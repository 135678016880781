.blogindex {
    .filters-container {
        .filters {
            flex-direction: column;
            text-align: center;
            display: flex;
            row-gap: 15px;

            h6 {
                font-family: "Montserrat-Medium";
                color: $secondary-font-color;
                text-transform: uppercase;
                letter-spacing: 1.4px;
                font-size: .875rem;
                line-height: 2rem;
                margin-bottom: 0;
            }

            > div {
                flex-direction: row;
                align-items: center;
                column-gap: 10px;
                overflow-x: auto;
                display: flex;

                @media screen and (min-width: 768px) {
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 15px;
                }

                button.category-chip {
                    border: 2px solid $icon-color-light;
                    font-family: "Montserrat-Medium";
                    color: $secondary-font-color;
                    text-transform: uppercase;
                    justify-content: center;
                    min-width: fit-content;
                    background: #FFFFFF;
                    letter-spacing: .5px;
                    line-height: 1.25rem;
                    border-radius: 20px;
                    align-items: center;
                    font-size: .75rem;
                    padding: 5px 15px;
                    display: flex;
                    height: 30px;

                    @media screen and (min-width: 768px) {
                        letter-spacing: 1.5px;
                        padding-right: 25px;
                        padding-left: 25px;
                        font-size: .875rem;
                        line-height: 2rem;
                        height: 40px;
                    }

                    &.active {
                        background-color: $catalpa-primary-color;
                        border-color: transparent;
                        color: #FFFFFF;
                    }

                    &:not(.active):hover {
                        background-color: rgba(175 189 202 / 15%) !important;
                    }
                }

                a {
                    font-family: "Montserrat-SemiBold";
                    color: $catalpa-primary-color;
                    text-transform: uppercase;
                    line-height: 1.125rem;
                    letter-spacing: 2px;
                    align-items: center;
                    font-size: .875rem;
                    column-gap: 5px;
                    display: flex;
                }
            }
        }
    }

    #stories-list {
        margin-top: 3.5rem;

        @media screen and (min-width: 820px) {
            margin-top: 6rem;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 8rem;
        }
    }
}

.blogpage .page-details-wrapper {
    .tags-wrapper {
        padding-right: 0;
        overflow-x: auto;
        padding-left: 0;
        display: flex;
        width: 100%;
        gap: 0.5rem;

        @media screen and (min-width: 768px) {
            flex-wrap: wrap;
            width: inherit;
        }

        .blog-tag {
            border-color: $icon-color-light;
            color: $secondary-font-color;
            text-transform: uppercase;
            min-width: fit-content;
            letter-spacing: 1.5px;
            font-size: 0.75rem;

            @media screen and (min-width: 992px) {
                letter-spacing: 1.75px;
                font-size: 0.9rem;
            }
        }
    }

    .author-wrapper {
        flex-shrink: 0;
        padding: 0;
        margin: 0;
    }
}

.blog-tag {
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3rem;
    color: #fff;
    font-family: 'Montserrat-Medium';
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.block-blogs_featured {
    background-color: $block-background-color;
    padding: 2rem 0 3rem 0;
    clip-path: ellipse(165% 100% at 50% 100%);

    @media screen and (max-width: 991px) {
        clip-path: ellipse(255% 100% at 50% 100%);
    }

    @media screen and (min-width: 992px) {
        padding-bottom: 6rem;
    }

    .btn-container {
        text-align: center;

        .btn {
            margin-top: 2rem;

            @media screen and (min-width: 992px) {
                margin-top: 5rem;
            }
        }
    }
}

.blogs-featured-container {
    h3 {
        padding-top: 2rem;
        margin-bottom: 2.5rem;

        @media screen and (min-width: 768px) {
            padding-top: 3.5rem;
            margin-bottom: 4rem;
        }
    }
}

.blog-card {
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    transition: transform ease .3s;
    border: 1px solid #E3E3D8;
    display: flex;

    &:hover img {
        transform: scale(1.05);
    }

    picture {
        flex: 1;
    }

    img {
        transition: transform ease .3s;
        object-position: center;
        object-fit: cover;
        height: 330px;
        width: 100%;

        @media screen and (min-width: 576px) and (max-width: 991px) {
            height: 390px;
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
            height: 445px;
        }

        @media screen and (min-width: 1200px) {
            height: 560px;
        }
    }

    .dark_gradient {
        background: linear-gradient(0deg, #2D3D47 30%, rgba(255 255 255 / 0%) 70%);
        opacity: 1;
        z-index: 1;
    }

    .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        justify-content: flex-end;
        padding: 1.5rem;
        position: absolute;
        width: 100%;
        z-index: 2;

        @media screen and (min-width: 1200px) {
            padding: 2rem;
        }

        h5 {
            color: #fff;
            margin-bottom: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        .tags-wrapper {
            flex-flow: row wrap;
            display: flex;
            gap: 0.5rem;

            > span {
                color: #fff;
            }
        }
    }
}

.author-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    margin-top: 1rem;
    max-width: fit-content;
    padding: 0 1.5rem;

    @media screen and (min-width: 1200px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .catalpa-author {
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: $border;
    }

    img {
        border-radius: 50%;
    }

    > p {
        color: $secondary-font-color;
        font-size: 0.75rem;
        letter-spacing: 1.5px;
        margin-bottom: 0;
        text-transform: uppercase;

        @media screen and (min-width: 820px) {
            font-size: 0.9rem;
        }
    }
}
