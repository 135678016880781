@font-face {
    font-family: "Lora-Regular";
    src: url("../fonts/Lora-Regular.woff2"), url("../fonts/Lora-Regular.woff");
}

@font-face {
    font-family: "Lora-Italic";
    src: url("../fonts/Lora-Italic.woff2"), url("../fonts/Lora-Italic.woff");
}

@font-face {
    font-family: "Lora-Bold";
    src: url("../fonts/Lora-Bold.woff2"), url("../fonts/Lora-Bold.woff");
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("../fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("../fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Caveat-SemiBold";
    src: url("../fonts/Caveat-SemiBold.ttf");
}

@font-face {
    font-family: "CircularStd-Light";
    src: url("../fonts/CircularStd-Light.woff2"), url("../fonts/CircularStd-Light.woff");
}

@font-face {
    font-family: "CircularStd-Medium";
    src: url("../fonts/CircularStd-Medium.woff2"), url("../fonts/CircularStd-Medium.woff");
}
