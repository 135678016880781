.block-publications_grid_block {
    .row {
        justify-content: center;
        row-gap: 2.5rem;

        @media screen and (min-width: 820px) {
            justify-content: space-between;
            row-gap: 6rem;
        }
    }
}

.card-publication {
    border: 1px solid #E3E3D8;
    box-shadow: 0 20px 40px 0 rgba(104, 119, 132, 0.15);
    border-radius: 6px;
    height: 100%;

    img {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        object-fit: cover;
        height: 180px;
        width: 100%;

        @media screen and (min-width: 576px) {
            height: 325px;
        }
    }

    .card-body {
        padding: 2.2rem;

        @media screen and (max-width: 991px) {
            padding: 1.2rem;
        }
    }

    h5 {
        margin-bottom: 1rem;
        color: #000;

        @media screen and (min-width: 992px) {
            margin-bottom: 1.5rem;
        }
    }

    p {
        line-height: 1.375rem;
        font-size: .875rem;
        margin-bottom: 0;

        @media screen and (min-width: 992px) {
            line-height: 1.75rem;
            font-size: 1.125rem;
        }
    }

    .card-footer {
        align-items: center;
        background-color: rgba(245, 245, 236, 0.65);
        border-top: 0;
        color: $catalpa-primary-color;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        text-transform: uppercase;
        padding: 2.2rem;

        @media screen and (max-width: 991px) {
            padding: 1.2rem;
        }

        a {
            font-family: 'Montserrat-SemiBold';
            font-size: 0.75rem;
            letter-spacing: 1.5px;
        }

        p {
            margin-bottom: 0;
            font-family: 'Montserrat-Medium';
            font-size: 0.9rem;
            letter-spacing: 1.5px;
        }

        i {
            font-size: 1.2rem;
            vertical-align: text-bottom;
            -webkit-text-stroke: 0.25px $catalpa-primary-color;
        }
    }

    .la-file-pdf {
        margin-right: 0.5rem;
    }

    .la-download {
        margin-left: 0.5rem;
    }
}
