//section header

.block-newsletter {
    background: $catalpa-primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    @media screen and (min-width: 820px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @media screen and (min-width: 1200px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    h4 {
        margin-bottom: 1.5rem;

        @media screen and (min-width: 992px) {
            margin-bottom: 2rem;
        }
    }

    p {
        font-family: "Montserrat-Medium";
        margin-bottom: 1.5rem;
        line-height: 1.5rem;
        font-size: 1rem;

        @media screen and (min-width: 992px) {
            margin-bottom: 2rem;
            line-height: 1.75rem;
            font-size: 1.125rem;
        }
    }

    .newsletter-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        justify-content: center;

        @media screen and (min-width: 992px) {
            flex-direction: row;
        }
    }

    .input-wrapper {
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 3px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        font-family: "Montserrat-Medium";
        font-size: 1rem;
        gap: 0.5rem;
        height: 3rem;
        line-height: 1.5;
        padding: 0;
        width: 100%;

        @media screen and (min-width: 992px) {
            flex: 1;
        }

        &:has(:focus) {
            box-shadow: inset 0 0 0 0.25rem rgba(30, 156, 176, 0.25);
            outline: solid 3px #1E9CB0;
        }

        label {
            font-size: 1.3rem;
            color: $icon-color-light;
            padding-left: 11px;
            padding-top: 3px;
        }

        input {
            height: 100%;
            min-height: 100%;
            padding: 0;
            border: none;
            background: transparent;

            &:focus {
                border: none;
                box-shadow: none;

                &::placeholder {
                    color: transparent;
                }
            }

            &::placeholder {
                color: $light-font-color;
            }
        }
    }

    #mc-embedded-subscribe {
        min-width: 250px;
    }
}

// form control
.form-control {
    // height: calc(1.5em + 0.75rem + 2px);
    background-color: transparent;
    // border-radius: 3px;
    // line-height: 1.5;
    // font-size: 1rem;

    &:focus {
        border-color: $catalpa-primary-color;
    }

    &.form-blue {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 1.5rem 1rem 1.5rem 2rem;
    }

    &.form-white {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-width: 2px;
        padding: 1.5rem;
    }
}

.form-blue::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #687784;
    opacity: 1;
    /* Firefox */
}

.form-blue:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #687784;
}

.form-blue::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #687784;
}

textarea.form-control {
    height: auto;
}

form .row {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-left: -5px;
    margin-right: -5px;
}

form [class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media screen and (orientation: portrait) and (min-width: 100px) and (max-width: 576px) {
    form [class^="col-sm"] {
        &:not(:last-child) {
            padding-bottom: 9px;
        }

        &:not(:first-child) {
            padding-top: 9px;
        }
    }
}
