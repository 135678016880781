.impact-area-card {
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    display: block;

    &:hover img {
        transform: scale(1.05);
    }

    img {
        transition: transform ease .3s;
        object-position: center;
        object-fit: cover;
        height: 320px;
        width: 100%;

        @media screen and (min-width: 992px) {
            height: 450px;
        }
    }
}
