/* contacts page */

.bg-contact-form {
    align-items: center;
    background-color: $block-background-color;
    display: flex;
    flex-flow: column;
    padding: 2rem 1.5rem;
    margin-bottom: 2px;

    @media screen and (min-width: 768px) {
        margin-bottom: 3.5rem;
        align-items: flex-start;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 1.5rem;
    }

    @media screen and (min-width: 992px) {
        margin-bottom: 6rem;
        padding: 3rem;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 8rem;
        padding: 6rem;
    }

    .form-wrapper {
        margin-bottom: 3rem;

        @media screen and (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    .input-wrapper {
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 3px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-flow: row nowrap;
        font-family: "Montserrat-Medium";
        font-size: 1rem;
        gap: 0.6rem;
        height: 3rem;
        line-height: 1.5;
        max-width: 400px;
        padding: 0.5rem;
        position: relative;
        margin-bottom: 1rem;
        border: 1px solid #C9C9B9;

        &--text-area {
            height: auto;
        }

        &:has(:focus) {
            outline: 2px solid #1E9CB0;
            border-color: #FFFFFF;
            box-shadow: none;
        }

        label {
            font-size: 1.3rem;
            color: $icon-color-light;
            padding-top: 3px;
        }

        input {
            position: relative;
            height: 100%;
            min-height: 100%;
            padding: 0;
            border: none;
            background: transparent;
            font-size: 0.87rem;
            width: 100%;

            &:required {
                width: 82%;
            }

            &:focus {
                border: none;
                box-shadow: none;
                outline: none;

                &::placeholder {
                    color: transparent;
                }
            }

            &::placeholder {
                color: $light-font-color;
            }

            + span {
                align-self: normal;
                margin-right: 11px;
                padding-top: 6px;
                position: absolute;
                right: 0;
                width: 20px;
            }
        }

        i {
            &.hidden {
                display: none;
            }

            &.check {
                color: $green;
                font-size: 20px;
            }

            &.cross {
                color: $red;
                font-size: 20px;
            }
        }

        textarea {
            @extend input;
            padding-top: 5px;
            padding-right: 11px;
            width: 100%;
        }

        &:has(textarea) label {
            align-self: normal;
        }

        &.invalid {
            outline: 2px solid $red;
            border-color: #FFFFFF;
            box-shadow: none;

            label {
                color: $red;
            }

        }

        &.valid {
            outline: 2px solid $green;
            border-color: #FFFFFF;
            box-shadow: none;

            label {
                color: $green;
            }

            input {
                &::placeholder {
                    color: $green;
                }
            }
        }
    }

    #capture_error {
        font-family: "Montserrat-Medium";
        color: $darkest-font-color;
        font-size: 0.9rem;
    }

    .see-more-link {
        font-size: .7rem;
    }
}

.btn.btn-primary {
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        margin-right: auto;
    }
}

#country-offices {
    text-align: center;

    @media screen and (min-width: 1024px) {
        text-align: left;

        h4 {
            margin-bottom: 3rem;
        }
    }
}

.offices {
    padding-top: 1.75rem;

    p {
        text-transform: uppercase;
        letter-spacing: 1.75px;
    }

    p,
    li {
        font-family: "Montserrat-Regular";
        color: $secondary-font-color;
        line-height: 1.375rem;
        font-size: 0.875rem;
    }

    img {
        margin-bottom: 1rem;
        width: auto;
    }

    .office {
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

}

// Small phones around the size of an iPhone 5S.
@media screen and (orientation: portrait) and (min-width: 100px) and (max-width: 576px) {

    .bg-contact-form {
        padding: 2rem 1.5rem;
    }

    #country-offices {
        text-align: center;
    }
}

.message-sent-wrapper {
    margin-bottom: 2rem;
    text-align: center;

    @media screen and (min-width: 1024px) {
        margin-bottom: 0;
        text-align: left;
    }
}
