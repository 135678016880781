@mixin search($height, $border) {
    input {
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        font-family: "Montserrat-Regular";
        padding: 10px 65px 10px 20px;
        background-color: #FFFFFF;
        border-radius: 35px;
        max-width: 100%;
        border: $border;
        height: $height;

        &:focus-visible {
            outline: 0;
        }
    }

    button {
        background-color: $catalpa-primary-color;
        justify-content: center;
        margin-left: -$height;
        border-radius: 35px;
        align-items: center;
        color: #FFFFFF;
        height: $height;
        width: $height;
        display: flex;
        border: none;

        &:hover {
            background-color: $catalpa-primary-hover-color;
        }
    }
}

#mainNavigation:not([data-bs-expanded]) form.search {
    display: none !important;
}

#mainNavigation form.search {
    @media screen and (max-width: 575px) {
        @include search(40px, 1px solid #C9C9B9);
    }

    @media screen and (min-width: 576px) {
        @include search(50px, 1px solid #C9C9B9);
    }

    input {
        width: 100%;
    }
}

.search-container {
    margin-bottom: 0 !important; // override body > main > div[class^="block-"]

    form.search {
        @media screen and (max-width: 991px) {
            @include search(55px, 1px solid #C9C9B9);
        }

        @media screen and (min-width: 992px) {
            @include search(65px, 1.5px solid #C9C9B9);
        }

        input {
            width: 450px;
        }
    }
}

.results-container {
    margin-bottom: 0 !important; // override body > main > div[class^="block-"]

    .container-xxl {
        flex-direction: column;
        display: flex;
        row-gap: 30px;
    }

    ul {
        flex-direction: column;
        margin-bottom: 0;
        padding-left: 0;
        display: flex;
        row-gap: 4rem;

        li {
            column-gap: 1rem;
            display: flex;

            @media screen and (min-width: 992px) {
                column-gap: 2rem;
            }

            * {
                margin-bottom: 0;
            }

            > div:not(.placeholder-image) {
                flex-direction: column;
                align-items: start;
                row-gap: .5rem;
                display: flex;

                @media screen and (min-width: 992px) {
                    row-gap: 1rem;
                }
            }

            h5 {
                line-height: 1.5rem;
                font-size: 1.25rem;

                @media screen and (min-width: 992px) {
                    font-size: 1.75rem;
                    line-height: 2rem;
                }
            }
        }
    }

    img {
        border-radius: 4px;
    }

    .placeholder-image {
        background-color: $block-background-color;
        border: 1px solid #E3E3D8;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        flex-shrink: 0;
        display: flex;
        height: 90px;
        width: 90px;

        @media screen and (min-width: 992px) {
            height: 200px;
            width: 200px;
        }

        i {
            color: $catalpa-primary-color;
            font-size: 1.5rem;

            @media screen and (min-width: 992px) {
                font-size: 3rem;
            }
        }
    }

    .page-type {
        font-family: "Montserrat-Medium";
        border: 1px solid #AFBDCA;
        text-transform: uppercase;
        border-radius: 20px;
        line-height: .875rem;
        font-size: .75rem;
        color: #4C4F54;
        padding: 0 30px;
        height: 25px;

        @media screen and (min-width: 992px) {
            font-size: .875rem;
            line-height: 1rem;
            height: 35px;
        }
    }
}
