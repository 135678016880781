$la-font-path: "line-awesome/dist/line-awesome/fonts";
@import "line-awesome/dist/line-awesome/scss/line-awesome";
@import "./_animations.scss";
@import "./variables";

//mobile first css
// main navigation section
nav#mainNavigation {
    position: fixed;
    width: 100%;
    z-index: 5;
    padding-bottom: 25px;
    padding-top: 25px;
    top: 0;

    // logo
    a {
        @extend .navbar-brand;

        svg {
            width: 120px;

            &.white path {
                fill: #FFFFFF;
            }

            &.blue path {
                fill: $catalpa-primary-color;
            }
        }
    }

    menu.socials {
        margin-bottom: 0;
    }

    // the always visible logo and button section
    > div:nth-child(1) {

        // expand nav toggle
        > .btn-main-nav {
            align-items: center;
            background-color: $catalpa-primary-color;
            border: none;
            border-radius: 30px;
            color: #fff;
            display: flex;
            gap: 0.5rem;
            height: 50px;
            justify-content: center;
            overflow: hidden;
            padding: 0 1rem;
            position: relative;
            text-transform: uppercase;
            z-index: 1;
            -webkit-backface-visibility: hidden;
            outline: none;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 110%;
                height: 100%;
                z-index: -1;
                -webkit-transition: background-color 0.5s;
                transition: background-color 0.5s;
            }

            &:hover::before {
                opacity: 1;
                background-color: $catalpa-primary-hover-color;
                -webkit-transform: rotate3d(0, 0, 1, 0deg);
                transform: rotate3d(0, 0, 1, 0deg);
                -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.5, 1);
                transition-timing-function: cubic-bezier(0.2, 1, 0.5, 1);
            }

            span {
                font-family: "Montserrat-SemiBold";
                font-size: 0.8rem;
                line-height: normal;
                letter-spacing: 1.5px;
                z-index: 2;

                &.close {
                    color: $catalpa-primary-color;
                    display: none;
                }
            }

            // button icon
            i {
                @extend .la-equals;
            }
        }
    }

    // expander section to show list of page links
    > div.nav-container {
        height: calc(100vh - 70px);
        padding-top: 20px;
        overflow-y: auto;

        .menu_title {
            @extend .fade-in-top;
            font-family: "Montserrat-Regular";
            text-transform: uppercase;
            letter-spacing: 1.38px;
            font-weight: 500;
            color: $secondary-font-color;
            font-size: .75rem;
        }

        a {
            @extend .fade-in-top;
            color: $secondary-font-color;
            font-family: "Lora-Bold";
            font-size: 1.5rem;
            text-align: center;
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 0;
            white-space: wrap;

            &.active-page {
                color: $catalpa-primary-color;
            }

            &:hover {
                transition: color 0.2s ease;
                color: $catalpa-primary-color;
            }
        }

        div {

            // Menu Animation set delay for each item
            @for $i from 1 through 20 {
                > :nth-child(#{$i}n) {
                    animation-delay: calc(0.1s + #{$i * 0.05}s) !important; // delay for background + offset delay for each item
                }
            }
        }

        .left-container,
        .right-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .item-wrapper {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-top: 30px;
            }
        }

        .right-container {
            > menu.socials {
                margin-top: 30px;
                margin-bottom: 40px;
            }

            .impact-wrapper {
                @extend .item-wrapper;

                .menu_title {
                    margin-bottom: 15px;
                }

                .button-container {
                    flex-direction: column;
                    align-items: center;
                    display: flex;

                    button {
                        @extend .fade-in-top;
                        max-width: fit-content;
                        border: none;
                        font-size: 0.85rem;
                        height: 50px;

                        @media screen and (max-width: 768px) {
                            font-size: 0.65rem;
                            height: 40px;
                        }
                    }
                }
            }
        }

        .left-container {
            > menu.socials {
                display: none;
            }
        }
    }

    // When menu is expanded
    &[data-bs-expanded] {
        // solid bg and full height
        background-color: $block-background-color !important;
        height: 100%;

        // change the button icon
        > div:nth-child(1) {
            > .btn-main-nav {
                border: none;
                background-color: transparent;
                padding-right: 0;
                padding-left: 0;

                &:hover {
                    &::before {
                        background-color: transparent;
                    }

                    i {
                        background-color: $catalpa-primary-color;
                        color: #fff;
                    }
                }

                span {
                    display: none;
                }

                span.close {
                    display: block;
                }

                i {
                    @extend .la-times;
                    border: 2px solid $catalpa-primary-color;
                    border-radius: 30px;
                    color: $catalpa-primary-color;
                    padding: 0.6rem;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        > div.nav-container {
            display: flex;
            max-width: 1320px;
            margin: 0 auto;
            padding: 5rem;
            align-items: baseline;

            .menu_title {
                margin-bottom: 0.75rem;
                font-size: .875rem;
            }

            .left-container,
            .right-container {
                align-items: flex-start;
                // height: 100%; TO BE ADDED ONCE WE HAVE IMPACT AREAS!!!
                row-gap: 200px;

                .section {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 2rem;
                }

                .item-wrapper {
                    align-items: flex-start;
                    margin-top: 0;
                }
            }

            .left-container {
                flex: 0 0 60%;

                menu.socials {
                    display: flex;
                }
            }

            .right-container {
                flex: 0 0 40%;
                gap: 2rem;
                margin-top: 0px;

                .impact-wrapper .button-container {
                    flex-flow: row wrap;
                }

                menu.socials {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        .left-container .section a {
            font-size: 2.5rem;
        }

        .right-container .section a {
            font-size: 1.5rem;
        }
    }

    @media screen and (min-width: 1200px) {
        .left-container {
            padding: 0 2rem 0 0;

            .section a {
                font-size: 3.5rem;
            }
        }

        .right-container {
            padding: 0 0 0 2rem;

            .section a {
                font-size: 2rem;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media screen and (orientation: portrait) and (min-width: 100px) and (max-width: 576px) {
    nav#mainNavigation {
        padding-bottom: 0;
        padding-top: 0;

        a {
            font-size: 2.5rem;

            svg {
                width: 100px;
            }
        }

        div:nth-child(1) > .btn-main-nav {
            min-width: 40px;
            height: 40px;
            padding: 0;

            &:hover,
            &:focus {
                &::before {
                    opacity: 0;
                }
            }

            span {
                display: none;
            }

            i {
                color: #FFFFFF;
                margin-top: 0;
            }
        }

        &[data-bs-expanded] {
            div:nth-child(1) > .btn-main-nav i {
                background-color: $catalpa-primary-color;
                justify-content: center;
                align-items: center;
                transition: none;
                color: #FFFFFF;
                display: flex;
                height: 40px;
                width: 40px;
            }
        }
    }
}
