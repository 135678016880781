/* products page on catalpa main site */
@import "call_to_action";

.productsindex main {
    > .section-wrapper {
        > div:first-child {
            clip-path: ellipse(128% 100% at 50% 0);
            padding-bottom: 50px;
            background: #FFFFFF;
        }

        > .page-block {
            background-color: $block-background-color;

            .container-lg > div {
                margin-bottom: -1px;
            }

            picture img {
                object-position: center;
                object-fit: cover;
                height: 400px;
                width: 100%;

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    height: 494px;
                    width: 936px;
                }

                @media screen and (min-width: 1200px) and (max-width: 1399px) {
                    height: 544px;
                    width: 1116px;
                }

                @media screen and (min-width: 1400px) {
                    height: 544px;
                    width: 1296px;
                }
            }

            .product-block {
                @include call_to_action(19px, 11px, 10px 30px);
                left: calc(var(--bs-gutter-x) * 0.5);
                justify-content: center;
                flex-direction: column;
                position: absolute;
                display: flex;
                width: 55%;
                bottom: 0;
                top: 0;

                p,
                h3 {
                    color: var(--product-text-colour);
                }

                > a {
                    > img {
                        height: 35px;
                        margin-bottom: 22px;
                    }
                }

                > p {
                    font-family: 'Montserrat-SemiBold';
                    font-size: .75rem;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                > h3 {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .productsindex main {
        > .section-wrapper {
            > .page-block {
                .product-block {
                    width: 45%;
                    left: 10%;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
    .productsindex main {
        > .section-wrapper {
            > div:first-child {
                padding-bottom: 6.5rem;
            }

            > .page-block {
                .product-block > p {
                    font-size: .875rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .productsindex main {
        > .section-wrapper {
            > .page-block {
                > .container-lg {
                    padding: 0;
                }
            }
        }
    }
}

// Large devices (desktops, 992px and up) // here
@include media-breakpoint-up(lg) {
    .productsindex main {
        > .section-wrapper {
            > .page-block {
                padding-bottom: 95px;
                padding-top: 195px;
                margin-top: -100px;

                .product-block {
                    @include call_to_action(23px, 12px, 13px 55px);
                    width: 35%;

                    > h3 {
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
    .productsindex main {
        > .section-wrapper {
            > .page-block {
                padding-bottom: 160px;
                padding-top: 225px;

                .product-block {
                    width: 45%;
                }
            }
        }
    }
}
