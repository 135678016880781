@mixin call_to_action($border-radius, $font-size, $padding) {
    .call-to-action {
        border-radius: $border-radius;
        font-size: $font-size;
        padding: $padding;
        font-family: "Montserrat-SemiBold";
        letter-spacing: 2px;
        text-transform: uppercase;
        border-width: 2px;
        border-style: solid;
        border-color: var(--product-text-colour);
        background-color: var(--product-text-colour);
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
        width: fit-content;

        &:hover {
            background-color: transparent;
            color: var(--product-text-colour) !important;
        }

        &.primary {
            color: $catalpa-primary-color;
        }

        &.bero {
            color: #0070F0;
        }

        &.vojo {
            color: #013246;
        }

        &.openly {
            color: #013246;
        }
    }
}
