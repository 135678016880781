.projectsindex {
    .filters-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        display: flex;
        row-gap: 15px;

        a {
            font-family: "Montserrat-SemiBold";
            color: $catalpa-primary-color;
            text-transform: uppercase;
            letter-spacing: 2px;
            align-items: center;
            font-size: .875rem;
            line-height: 2rem;
            column-gap: 5px;
            display: flex;

            @media screen and (max-width: 767px) {
                &.draw-closed ~ .filters {
                    transition: max-height 0.3s ease-out, opacity 0.2s;
                    max-height: 0;
                    opacity: 0;
                }

                &.draw-open ~ .filters {
                    transition: max-height 0.3s ease-in, opacity 0.2s;
                    max-height: 500px;
                }
            }

            &.draw-closed i {
                @extend .la-angle-down;
            }

            &.draw-open i {
                @extend .la-angle-up;
            }

            i {
                -webkit-text-stroke: thin;
            }
        }

        .filters {
            flex-direction: column;
            display: flex;
            row-gap: 15px;

            h6 {
                font-family: "Montserrat-Medium";
                color: $secondary-font-color;
                text-transform: uppercase;
                letter-spacing: 1.4px;
                font-size: .875rem;
                line-height: 2rem;
                margin-bottom: 0;
            }

            > div {
                flex-flow: column nowrap;
                align-items: center;
                display: flex;
                gap: 10px;

                @media screen and (min-width: 768px) {
                    justify-content: center;
                    flex-flow: row wrap;
                }

                button:not(.active):hover {
                    background-color: rgba(175 189 202 / 15%) !important;
                }
            }
        }
    }

    #project-list {
        margin-top: 3.5rem;

        @media screen and (min-width: 820px) {
            margin-top: 6rem;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 8rem;
        }
    }
}

.projectpage {
    .page-details-wrapper {
        p {
            color: $secondary-font-color;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 0.75rem;
            margin-bottom: 0;

            @media screen and (min-width: 992px) {
                letter-spacing: 1.75px;
                font-size: 0.9rem;
            }
        }

        & > div {
            justify-content: center;
            flex-wrap: wrap;
            display: flex;
            gap: 0.5rem;

            .category-chip-tag {
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                padding: 0 20px;
                display: flex;
                height: 40px;
                margin: 0;

                @media screen and (min-width: 992px) {
                    height: 50px;
                }
            }
        }
    }

    .supported-partners-container {
        .container-xxl > div > div > div {
            padding-bottom: 2rem;
            border-top: $border;
            padding-top: 2rem;
            gap: 1rem 30px;

            @media screen and (min-width: 768px) {
                padding-bottom: 4rem;
                padding-top: 4rem;
            }

            > div {
                &:first-child {
                    column-gap: 30px;
                    overflow-x: auto;
                }

                &:last-child {
                    column-gap: 1rem;
                }
            }

            span {
                font-family: "Montserrat-SemiBold";
            }

            a {
                font-family: "Montserrat-Medium";
            }
        }
    }
}

// PROJECT CARDS

// card UI

.project-card {
    .card {
        height: 100%;
    }

    .image-wrapper {
        border-radius: 6px;
        position: relative;
        margin-bottom: 1rem;
        overflow: hidden;

        &:hover img {
            transform: scale(1.05);
        }

        img {
            transition: transform ease .3s;
            object-fit: cover;
            width: 100%;
        }
    }

    .see-more-link {
        margin: auto 0 0;
    }
}

.card {
    border: 0;
    border-radius: 0;
    flex: 0 1 calc(25% - 1em);
}

// card text styles

.filtered {
    display: none !important
}

// project card images
.card-img {
    width: 100%;
    border-radius: 0;
    object-fit: cover;
    transition: all .25s ease;
}

.block-single_project_block,
.block-dual_projects_block,
.project-card {
    span {
        font-family: "Montserrat-Medium";
        color: $secondary-font-color;
        text-transform: uppercase;
        display: inline-block;
        line-height: 1.375rem;
        letter-spacing: 2px;
        font-size: 0.9rem;
    }

    h4 > a,
    h5 > a {
        color: #000000;

        &:hover {
            transition: color 0.2s ease;
            color: $catalpa-primary-hover-color;
        }
    }

    span,
    h4,
    h5,
    p {
        margin-bottom: 0.75rem;

        @media screen and (min-width: 992px) {
            margin-bottom: 1.25rem;
        }
    }
}

.block-single_project_block {
    img {
        transition: transform ease .3s;
        object-position: center;
        object-fit: cover;
        height: 200px;
        width: 100%;

        @media screen and (min-width: 576px) and (max-width: 991px) {
            height: 350px;
        }

        @media screen and (min-width: 992px) {
            height: 515px;
        }
    }

    h3 + .pane-wrapper {
        margin-top: 1.5rem;
    }

    .pane-wrapper {
        @media screen and (max-width: 575px) {
            padding-right: 0;
            padding-left: 0;
        }

        .text-wrapper {
            background-color: $block-background-color;
            border-radius: 8px;
            margin-top: -4rem;
            min-height: 350px;
            padding: 6rem calc(var(--bs-gutter-x) * 0.5) 2.5rem;
        }

        .image-wrapper {
            border-radius: 6px;
            position: relative;
            margin-right: calc(var(--bs-gutter-x) * 0.5);
            margin-left: calc(var(--bs-gutter-x) * 0.5);
            overflow: hidden;

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }

        &.right {
            .text-wrapper {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                padding-right: 10rem;
                padding-left: 3rem;
            }

            .image-wrapper {
                margin-right: 0;
                margin-left: -8rem;
            }

        }
    }

    @media screen and (min-width: 768px) {
        h3 + .pane-wrapper {
            margin-top: 8rem;
        }

        .pane-wrapper {
            margin-top: 9.5rem;
            position: relative;

            > div > div {
                flex-direction: row-reverse;
                display: flex;
            }

            .image-wrapper {
                margin-left: 0;
                margin-right: 0;
                position: absolute;
                top: -6rem;
                max-width: 40%;
            }

            .text-wrapper {
                margin-top: 0;
                margin-right: 5rem;
                padding: 3rem;
                padding-right: 43%;
            }
        }
    }

    @media screen and (min-width: 992px) {
        h3 + .pane-wrapper {
            margin-top: 9rem;
        }

        .pane-wrapper {
            margin-top: 12rem;

            .text-wrapper {
                padding-right: 34%;
                min-height: 515px;
                margin-right: 10rem;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        h3 + .pane-wrapper {
            margin-top: 10rem;
        }

        .pane-wrapper {
            margin-top: 14rem;

            .text-wrapper {
                padding-right: 30%;
                margin-right: 14rem;
            }
        }
    }
}

.block-dual_projects_block {
    @extend .block-single_project_block;
    padding-top: 0 !important;

    img {
        height: 200px;

        @media screen and (min-width: 576px) and (max-width: 991px) {
            height: 300px;
        }

        @media screen and (min-width: 992px) {
            height: 400px;
        }
    }

    @media screen and (max-width: 767px) {
        .pane-wrapper {
            margin-bottom: 3.5rem;
        }
    }

    @media screen and (min-width: 768px) {
        .pane-wrapper {
            margin-top: 7.8rem !important;

            &:nth-child(odd) {
                > div > div {
                    flex-direction: row;
                }

                .text-wrapper {
                    padding-left: 43%;
                    padding-right: 2rem;
                    margin-left: 5rem;
                    margin-right: 0;
                }
            }

            &:nth-child(even) {
                .text-wrapper {
                    padding-right: 43%;
                    margin-right: 5rem;
                }
            }

            .image-wrapper {
                top: -3rem;
            }

            .text-wrapper {
                padding: 3rem;
                min-height: 300px;
            }
        }
    }

    @media screen and (min-width: 992px) {
        .pane-wrapper {

            &:nth-child(odd) .text-wrapper {
                padding-right: 3rem;
                margin-left: 11rem;
                padding-left: 34%;
                margin-right: 0;
            }

            &:nth-child(even) .text-wrapper {
                margin-right: 11rem;
                padding-right: 34%;
            }

            .text-wrapper {
                min-height: 400px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .pane-wrapper {
            &:nth-child(odd) .text-wrapper {
                margin-left: 20rem;
                padding-left: 20%;
            }

            &:nth-child(even) .text-wrapper {
                margin-right: 20rem;
                padding-right: 20%;
            }
        }
    }
}

// new three project block styles
// parent block styles - project_featured.html
.block-related-projects {
    display: flex;
    flex-direction: column;

    .projects-cta {
        align-self: center;
        margin-top: 2rem;
        max-width: fit-content;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 4rem;
            margin-bottom: 0;
        }
    }

    // child block styles - related_project_card.html
    .featured-projects-wrapper {
        @include scrollbars;
        overflow-x: auto;
        display: flex;
        flex-flow: row nowrap;

        @media screen and (max-width: 991px) {
            padding-bottom: 1.25rem;
        }
    }
}
