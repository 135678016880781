// Colors
$catalpa-primary-color: #008290;
$catalpa-primary-hover-color: #006873;
$block-background-color: #f5f5ed;
$border-color: #E8EAED;
$secondary-font-color: #4C4F54;
$darkest-font-color: #212529;
$icon-color-light: #AFBDCA;
$light-font-color: #687784;
$productsite-primary-color: #0070F0;
$productsite-light-color: #6AAFFF;
$carousel-tag-color: #9CA7B0;
$red: #F3215B;
$green: #19D4AC;
$border: 2px solid #E3E3D8;
$breakpoint-mobile-sm: 768px;
$breakpoint-tablet: 1200px;
$breakpoint-desktop: 1600px;
$gutter: 1.25rem; //20px

// scrollbar styles for horizonal scrollbar
// added to overflow-x: scroll containers on mobile.
// should add "scroll-snap-align: center;" to item 
// in scrollable container
@mixin scrollbars {
    scroll-snap-type: x mandatory;
    // For Google Chrome
    &::-webkit-scrollbar {
        height: 4px;
        min-height: 4px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background-color: $catalpa-primary-color;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track:horizontal {
        background-color: $block-background-color;
        border-radius: 8px;
        margin-left: 20vw;
        margin-right: 20vw;
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $catalpa-primary-color;
        scrollbar-track-color: $block-background-color;
    }
}
