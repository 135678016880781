.block-body_text {
    *:last-child {
        margin-bottom: 0;
    }

    *:empty:not(hr):not(img) {
        display: none;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 2rem;
        margin-top: 2rem;
        color: #000000;

        &:first-child {
            margin-top: 0;
        }

        &:only-child {
            margin-bottom: 0 !important; // override .generalpage .block-body_text h2
        }
    }

    p > b {
        font-family: "Montserrat-SemiBold";
    }

    p > a {
        text-decoration: underline;
    }

    li {
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        margin: 0 1rem;

        &::marker {
            font-family: "Montserrat-SemiBold";
        }
    }

    p,
    li {
        font-family: "Montserrat-Regular";
        color: $secondary-font-color;
        line-height: 1.5rem;
        font-size: 1rem;

        @media screen and (min-width: 992px) {
            line-height: 2rem;
            font-size: 1.125rem;
        }
    }

    blockquote {
        font-family: "Lora-Bold";
        font-size: 1.25rem;
        color: #000;
        padding-right: var(--bs-gutter-x);
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-top: 2rem;
        margin-right: calc(var(--bs-gutter-x) * -0.5);
        margin-left: calc(var(--bs-gutter-x) * 0.5);
        border-left: 10px solid $block-background-color;

        @media screen and (min-width: 1024px) {
            padding-right: var(--bs-gutter-x);
            padding-bottom: 4rem;
            padding-left: 4rem;
            padding-top: 4rem;
            font-size: 2rem;
            margin: 3rem 0;
        }
    }

    img.richtext-image {
        margin-top: calc(40px - 16px);
        margin-bottom: 40px;
        max-width: 100%;
        display: block;
        height: auto;
        width: auto;

        &.full-width {
            margin-right: auto;
            margin-left: auto;
        }

        &.left {
            margin-right: auto;
        }

        &.right {
            margin-left: auto;
        }
    }

    hr {
        margin: 2rem 0;
    }

    .block-action {
        margin-top: 2rem;

        @media screen and (min-width: 992px) {
            margin-top: 3rem;
        }
    }
}

.home .block-body_text,
.generalpage .block-body_text {
    @media screen and (min-width: 992px) {

        p,
        li {
            line-height: 2.375rem;
            font-size: 1.375rem;
        }
    }
}
