$socials-desktop: 50px;
$socials-mobile: 40px;

menu.socials {
    @include make-row();
    justify-content: center;
    margin-bottom: ($gutter * 2.5);
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    flex-wrap: nowrap;
    column-gap: 15px;

    li {
        list-style: none;
        height: $socials-mobile;
        width: $socials-mobile;

        > a {
            transition: all 0.2s ease;
            background-repeat: no-repeat;
            background-size: contain;
            height: $socials-mobile;
            width: $socials-mobile;
            display: inline-block;

            &#twitter-link {
                background-image: url("/src/images/x.svg");

                &:hover {
                    background-image: url("/src/images/x-active.svg");
                }
            }

            &#linkedin-link {
                background-image: url("/src/images/Linkedin.svg");

                &:hover {
                    background-image: url("/src/images/Linkedin-active.svg");
                }
            }

            &#facebook-link {
                background-image: url("/src/images/Facebook.svg");

                &:hover {
                    background-image: url("/src/images/Facebook-active.svg");
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
    menu.socials {
        li {
            height: $socials-desktop;
            width: $socials-desktop;

            > a {
                height: $socials-desktop;
                width: $socials-desktop;
            }
        }
    }
}
