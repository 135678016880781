.block-three_images_with_header_text {

    .text-wrapper {
        background-color: $block-background-color;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        text-align: center;
        padding: 4rem;

        @media screen and (min-width: 992px) {
            padding-right: 8rem;
            padding-left: 8rem;
        }

        h3 {
            margin-bottom: 0.75rem;

            @media screen and (min-width: 992px) {
                margin-bottom: 1.25rem;
            }
        }

        p {
            line-height: 1.5rem;
            margin-bottom: 0;
            font-size: 1rem;

            @media screen and (min-width: 992px) {
                line-height: 1.875rem;
                font-size: 1.25rem;
            }
        }
    }

    #three-image-carousel {
        background-color: $block-background-color;
        margin: 0;
        margin-top: 0;

        img {
            object-position: center;
            object-fit: cover;
            height: 235px;
            width: 100%;

            @media screen and (min-width: 576px) {
                height: 450px;
            }
        }

        @media screen and (min-width: 768px) {
            justify-content: space-between;

            .image-wrapper {
                border-radius: 6px;
                flex: 1 1 0;
                min-width: 32% !important;
                max-width: 32% !important;
                transform: none !important;
            }
        }
    }

    .link-wrapper {
        background-color: $block-background-color;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        text-align: center;
        padding: 4rem 0;
    }
}
