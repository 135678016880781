.keen-slider {
    align-items: center;
}

.slider-wrapper {
    position: relative;

    .arrow {
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        display: flex;
        height: 50px;
        width: 50px;

        &--disabled {
            pointer-events: none;
            opacity: 0.6;
        }

        > i {
            color: $catalpa-primary-color;
        }

        @media screen and (max-width: 991px) {
            &--right {
                right: 1rem;
            }

            &--left {
                left: 1rem;
            }
        }

        @media screen and (min-width: 992px) {
            &--right {
                right: 3rem;
            }

            &--left {
                left: 3rem;
            }
        }
    }

    .dots {
        justify-content: center;
        padding: 10px 0;
        display: flex;

        .dot {
            background-color: #E3E3D8;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 5px;
            padding: 5px;
            border: none;
            height: 10px;
            width: 10px;

            &:focus {
                outline: none;
            }
        }

        @media screen and (min-width: 820px) {
            bottom: 1.5rem;
        }
    }
}

.block-three_images_with_header_text {
    .arrow {
        transform: translateY(-50%);
        bottom: 50%;
        top: 50%;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}

.block-blogs_featured {
    @media screen and (max-width: 991px) {
        .keen-slider:has(> :last-child:nth-child(1)) {
            justify-content: center;
        }
    }

    @media screen and (min-width: 992px) {

        .keen-slider:has(> :last-child:nth-child(1)),
        .keen-slider:has(> :last-child:nth-child(2)) {
            justify-content: center;
        }
    }

    .dots {
        .dot--active {
            background-color: $catalpa-primary-color;
        }

        @media screen and (min-width: 992px) {
            display: none;
        }
    }
}

.block-products_featured {
    .dots {
        position: absolute;
        bottom: 60px;
        right: 0;
        left: 0;

        .dot--active {
            background-color: #FFFFFF;
            opacity: 1;
        }
    }
}

.block-image_carousel {
    .arrow {
        transform: translateY(-50%);
        bottom: 50%;
        top: 50%;
    }
}
