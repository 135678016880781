.block-half_image_half_text {
    .container-xxl {
        flex-direction: column;
        display: flex;

        @media screen and (max-width: 575px) {
            padding: 0;
        }

        @media screen and (min-width: 768px) {
            flex-flow: row nowrap;
        }
    }

    .image-wrapper {
        @media screen and (min-width: 576px) and (max-width: 767px) {
            order: 1;

            img {
                border-radius: 6px 6px 0 0;
            }
        }

        @media screen and (min-width: 768px) {
            &.right {
                order: 2;

                img {
                    border-radius: 0 6px 6px 0;
                }
            }

            &.left {
                order: 1;

                img {
                    border-radius: 6px 0 0 6px;
                }
            }
        }
    }

    img {
        object-position: center;
        object-fit: cover;

        @media screen and (max-width: 767px) {
            height: 400px;
            width: 100%;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 500px;
            width: 400px;
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
            height: 525px;
            width: 525px;
        }

        @media screen and (min-width: 1200px) {
            height: 600px;
            width: 600px;
        }
    }

    .text-wrapper {
        background-color: $block-background-color;
        justify-content: center;
        flex-direction: column;
        display: flex;

        @media screen and (min-width: 576px) and (max-width: 767px) {
            order: 2;
            border-radius: 0 0 6px 6px;
        }

        @media screen and (min-width: 768px) {
            &.right {
                order: 2;
                border-radius: 0 6px 6px 0;
            }

            &.left {
                order: 1;
                border-radius: 6px 0 0 6px;
            }
        }

        @media screen and (max-width: 991px) {
            padding: 2rem;
        }

        @media screen and (min-width: 992px) {
            padding: 2.5rem 10% 2.95rem 5%;
        }

        .card-sub-title {
            margin-top: 0;

            &:empty {
                display: none;
            }
        }
    }

    span {
        font-family: "Montserrat-Medium";
        color: $secondary-font-color;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 2px;
        line-height: 1.375rem;
        font-size: 0.9rem;
    }

    h4 {
        color: #000000;
    }

    span,
    h4,
    p {
        margin-bottom: 0.75rem;

        @media screen and (min-width: 992px) {
            margin-bottom: 1.25rem;
        }
    }
}
