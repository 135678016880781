.block-illustrations_block {
    h4 {
        text-align: center;
        margin-bottom: 2rem;

        @media screen and (min-width: 768px) {
            margin-bottom: 3.5rem;
        }
    }

    .content-wrapper {
        @include scrollbars;
        scroll-snap-type: x mandatory;
        padding-bottom: 1rem;
        overflow-y: auto;

        @media screen and (min-width: 992px) {
            padding-bottom: 0;
            overflow-y: unset;
        }

        > div {
            flex-wrap: nowrap;

            @media screen and (min-width: 992px) {
                flex-wrap: wrap;
                row-gap: 1.5rem;
            }
        }

        .item-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            scroll-snap-align: center;
            text-align: center;

            h6 {
                margin-bottom: 1rem;
                margin-top: 1rem;

                @media screen and (min-width: 992px) {
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;
                }
            }

            p {
                font-family: "Montserrat-Medium";
                font-size: 1.1rem;
                margin-bottom: 0;
            }

            .image-wrapper {
                img {
                    object-fit: contain;
                    height: 205px;

                    @media screen and (min-width: 992px) {
                        height: 215px;
                    }
                }
            }
        }
    }

    .block-action {
        margin-top: 2rem;

        @media screen and (min-width: 992px) {
            margin-top: 3rem;
        }

        a {
            display: block;
            margin: 0 auto;
        }
    }
}
