.impact_pills {
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    max-width: 70%;
    display: flex;
    z-index: 1;
    right: 0;

    @media screen and (max-width: 991px) {
        top: 1rem;
    }

    @media screen and (min-width: 992px) {
        top: 1.5rem;
    }
}

.impact_pills .category-chip-tag {
    border-radius: 25px 0 0 25px;
    align-items: center;
    font-size: .75rem;
    margin-right: 0;
    column-gap: 5px;
    padding: 0 20px;
    display: flex;

    @media screen and (max-width: 991px) {
        height: 40px;
    }

    @media screen and (min-width: 992px) {
        height: 50px;
    }
}

.impact-pill {
    font-family: "Montserrat-SemiBold";
    background-color: $secondary-font-color;
    border: 2px solid $secondary-font-color;
    text-transform: uppercase;
    justify-content: center;
    display: inline-flex;
    border-radius: 25px;
    align-items: center;
    line-height: 1rem;
    font-size: .75rem;
    color: #FFFFFF;
    padding: 0 20px;
    height: 50px;
    gap: 10px;
    position: relative;

    &.active > .la-check {
        display: flex;
    }

    &:not(.active) {
        background-color: #FFFFFF !important;
        border-color: $icon-color-light !important;
        color: $secondary-font-color !important;
    }

    .la-check {
        background: $catalpa-primary-color;
        -webkit-text-stroke: 1px #FFFFFF;
        border: 2px solid #FFFFFF;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        color: #FFFFFF;
        display: none;
        height: 20px;
        width: 20px;
        top: -10px;
        right: 0;

        @media screen and (min-width: 767px) {
            height: 25px;
            width: 25px;
            top: -13px;
        }
    }
}
