.block-products_featured {
    @include call_to_action(23px, 12px, 13px 55px);

    margin-top: 0 !important;

    .product-card-container {
        .product-card {
            img {
                object-position: center;
                object-fit: cover;
                height: 520px;
                width: 100%;

                @media screen and (min-width: 992px) {
                    height: 630px;
                }
            }
        }

        .content-wrapper {
            justify-content: center;
            flex-direction: column;
            position: absolute;
            display: flex;
            row-gap: 1rem;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;

            h2,
            p {
                color: var(--product-text-colour);
                margin-bottom: 0;
                width: 55%;
            }

            @media screen and (min-width: 992px) {
                row-gap: 1.5rem;

                p {
                    font-size: 1.25rem;
                }

                h2,
                p {
                    width: 50%;
                }
            }
        }
    }
}
