.block-key_text {
    .container-xxl {
        & > div > div > div {
            flex-direction: column;
            display: flex;

            & > div {
                display: flex;

                *:last-child {
                    margin-bottom: 0;
                }

                *:empty {
                    display: none;
                }

                p {
                    font-family: "Montserrat-Regular";

                    b {
                        font-family: "Montserrat-SemiBold";
                    }
                }

                ul {
                    padding-left: 15px;
                }

                li {
                    font-family: "Montserrat-Regular";
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .container-xxl {
            h3 {
                margin-bottom: 2rem;
            }

            & > div > div > div {
                row-gap: 2rem;

                & > div {
                    flex-direction: column;
                    row-gap: 1rem;

                    li,
                    p {
                        line-height: 1.75rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .container-xxl {
            h3 {
                margin-bottom: 4rem;
            }

            & > div > div > div {
                row-gap: 6rem;

                & > div {
                    column-gap: 6rem;

                    h4 {
                        line-height: 2.5rem;
                        font-size: 1.875rem;
                        width: 30%;
                    }

                    h4 + div {
                        width: 70%;
                    }

                    li,
                    p {
                        line-height: 2rem;
                        font-size: 1.125rem;
                    }
                }
            }
        }
    }
}
