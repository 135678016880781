@import "variables";

$primary: $catalpa-primary-color;
$secondary: #ff4962;
$light: #fff;
$white: #fff;
$dark: #000000;

@import "~bootstrap/scss/bootstrap";
@import "~keen-slider/keen-slider";

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "white": $white,
    "dark": $dark
);

$body-bg: $white;
$body-color: $dark;

@import "fonts";

/* Components/page specific styling */
@import "search";
@import "contact";
@import "forms";
@import "header_text";
@import "header_image";
@import "body_text";
@import "body_image";
@import "navbar";
@import "projects";
@import "catalpians";
@import "products";
@import "socials";
@import "half_image_half_text";
@import "image_and_text_block";
@import "illustrations_block";
@import "blogs";
@import "text_and_list_block";
@import "elements_block";
@import "three_images_with_header_text";
@import "key_text";
@import "image_carousel";
@import "quotes";
@import "keen_slider";
@import "impact_areas";
@import "impact_pills";
@import "publications";
@import "embed";
@import "products_featured";
@import "tooltip";
@import "product_site/footer";

body {
    font-family: "Lora";
    overflow-x: hidden;
}

h1 {
    font-family: "Lora-Bold";
    font-size: 2rem;
    line-height: 2.5rem;

    @media screen and (min-width: 992px) {
        font-size: 5.375rem;
        line-height: 6rem;
    }
}

h2 {
    font-family: "Lora-Bold";
    font-size: 1.375rem;
    line-height: 1.875rem;

    @media screen and (min-width: 992px) {
        font-size: 3.25rem;
        line-height: 4.375rem;
    }
}

h3 {
    font-family: "Lora-Bold";
    font-size: 1.375rem;
    line-height: 1.875rem;

    @media screen and (min-width: 992px) {
        font-size: 2.625rem;
        line-height: 3.875rem;
    }
}

h4 {
    font-family: "Lora-Bold";
    font-size: 1.25rem;
    line-height: 1.75rem;

    @media screen and (min-width: 992px) {
        font-size: 2.5rem;
        line-height: 3.125rem;
    }
}

h5 {
    font-family: "Montserrat-SemiBold";
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media screen and (min-width: 992px) {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }
}

h6 {
    font-family: "Montserrat-SemiBold";
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media screen and (min-width: 992px) {
        font-size: 1.5rem;
        line-height: 1.875rem;
    }
}

a {
    text-decoration: none;
    color: $catalpa-primary-color;
}

a:hover {
    color: #166877;
    text-decoration: none;
    transition: color 0.1s ease;
    cursor: pointer;
}

p {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: $secondary-font-color;

    @media screen and (min-width: 1024px) {
        font-size: 1.125rem;
    }
}

ul#project-talking-points {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

body > main > div[class^="block-"] {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
}

body > main > div[class^="block-header"] {
    margin-top: 0;
}

body > main > div:last-child:is(.block-blogs_featured) {
    margin-bottom: 2px;
}

body > main > div.block-anchor_target {
    margin: 0 !important;
}

.la-2x.la-arrow-right,
.la-2x.la-arrow-left {
    font-size: 1.4rem;
}

// Wagtail blocks

.block-richtext {
    margin-bottom: 3rem;
}

// Container
.template-404 > .container {
    margin-bottom: 8rem;
    margin-top: 8rem;
}

.figure-caption {
    font-size: 0.9rem;
    margin-top: 25px;
    color: $light-font-color;
    font-family: "Montserrat-Medium";

}

[class^="icon-"],
[class*=" icon-"] {
    font-family: "icomoon" !important; // use !important to prevent issues with browser extensions that change fonts
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right2 {
    &:before {
        content: "\e900";
    }
}

.icon-arrow-left2 {
    &:before {
        content: "\e901";
    }
}


// Job list items
#whr_embed_hook {
    padding-bottom: 20px;

    li.whr-item:not(:first-child) {
        margin-top: 1.5rem;
    }

    .whr-info {
        list-style-type: none;
    }

    .whr-title {
        font-family: "Montserrat-SemiBold";
    }

    .whr-items {
        list-style-type: none;
        padding-left: 0;
    }

    li.whr-item {
        min-height: 85px;
    }

    li.whr-dept,
    li.whr-date,
    .whr-location > span {
        display: none;
    }

    li.whr-location {
        text-align: left;
        position: relative;
        top: -25px;
        left: -15px;
        font-size: 1rem;
        color: #2929299e;
        font-family: "Montserrat-SemiBold";
    }

    li.whr-location:before {
        position: relative;
        top: 23px;
        left: -28px;
        display: block;
        content: " ";
        background-image: url("../images/location-pin-blue.svg");
        background-size: 25px 25px;
        height: 25px;
        width: 25px;
    }
}

//button styles
.btn:not(.btn-main-nav):not(.btn-nav) {
    border-radius: 3rem;
    font-family: "Montserrat-SemiBold";
    font-size: 0.9rem;
    letter-spacing: 2px;
    padding: 0.8rem 2.5rem;
    text-transform: uppercase;
    width: fit-content;
    min-width: 224px;

    @media screen and (min-width: 820px) {
        min-width: 288px;
    }
}

.btn-primary {
    color: var(--bs-white);
}

.btn-primary:hover,
.btn-primary:active {
    background-color: var(--bs-link-hover-color) !important;
    border-color: var(--bs-link-hover-color) !important;
    color: var(--bs-white);
}

.btn-outline-primary {
    background-color: #fff;
    border: 2px solid;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: var(--bs-white) !important;
    border-color: var(--bs-primary) !important;
}

.btn-light {
    background-color: #FFFFFF;
    border-radius: 3rem;
    color: $catalpa-primary-color;
    font-family: "Montserrat-SemiBold";
    font-size: 0.85rem;
    padding: 0.6rem 2.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    width: fit-content;

    &:hover {
        color: $catalpa-primary-color;
        background-color: #e8e8e8;
        border-color: #e8e8e8;
    }
}

.btn-block {
    display: block;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.btn-see-more {
    color: $catalpa-primary-color;
    font-family: "Montserrat-SemiBold";
    text-transform: uppercase;
    border: 2px solid $catalpa-primary-color;
    background: transparent;
    border-radius: 3rem;
    font-size: 0.7rem;
    font-weight: bold;
    padding-top: 8px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:hover {
        background: #1E9CB0;
        border: 2px solid #1E9CB0;
        color: #fff;
    }
}

.btn.btn-secondary {
    border-radius: 3rem;
    padding: 0.6rem 1rem;
    font-size: 1.25rem;
    opacity: 0.9;
}

.see-more-link {
    text-transform: uppercase;
    font-family: "Montserrat-SemiBold";
    font-size: 0.9rem;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 1.125rem;

    > i {
        margin-left: 1px;
        transition: all 0.3s ease;
    }

    &:hover {
        > i {
            margin-left: 0.3rem;

            &.la-2x.la-arrow-left {
                margin-left: 0;
                margin-right: 0.3rem;
            }
        }
    }
}

.link-bottom {
    position: relative;
    top: 460px;
}

.home-photo-block-3 {
    margin-top: -480px;
    position: absolute;
}

// logo carousel

.block-partners {
    box-shadow: 0 -2px 0 0 #E3E3D8;
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
    text-align: center;

    @media screen and (min-width: 992px) {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    @media screen and (min-width: 1200px) {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    h4 {
        margin-bottom: 2rem;
    }

    img {
        object-fit: contain;
        max-height: 100px;

        @media screen and (min-width: 992px) {
            max-height: 150px;
        }
    }
}

// badges
.is-new-badge {
    font-family: "Montserrat-SemiBold";
    background-color: #EA0048;
    text-transform: uppercase;
    justify-content: center;
    letter-spacing: 2px;
    border-radius: 20px;
    align-items: center;
    font-size: 0.75rem;
    width: fit-content;
    padding: 5px 20px;
    color: #FFFFFF;
    display: flex;
    height: 40px;
}

.project-card .is-new-badge,
.card-publication .is-new-badge {
    position: absolute;
    z-index: 4;

    @media screen and (max-width: 991px) {
        left: 1rem;
        top: 1rem;
    }

    @media screen and (min-width: 992px) {
        left: 1.5rem;
        top: 1.5rem;
    }
}

// icon adjustments

.inline-icon {
    color: $secondary-font-color;
    font-size: 1.8rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.small-icon {
    width: 14px;
}


.element-block img {
    width: 70px;
    height: 70px;
}

.element-block p {
    font-size: 1em;
    padding: 0 2rem 0 2rem;
}

.support-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 2px solid #E3E3D8;
    border-bottom: 2px solid #E3E3D8;
}

.category-chip-tag {
    font-size: 0.8rem;
    display: inline-block;
    font-family: "Montserrat-SemiBold";
    background-color: transparent;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    color: #fff;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 12px;
    padding-bottom: 12px;
    letter-spacing: .8px;
    position: relative;
    width: inherit;
    z-index: 1;
}

.impactareapage .page-banner .category-chip-tag {
    text-transform: capitalize;
    font-family: "Lora-Bold";
    justify-content: center;
    border-radius: 120px;
    align-items: center;
    line-height: normal;
    text-align: center;
    width: fit-content;
    column-gap: 10px;
    min-height: 45px;
    max-width: 700px;
    font-size: 1.5rem;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding-right: 40px;
        padding-left: 40px;
        min-height: 80px;
        font-size: 2.75rem;
    }

    @media screen and (min-width: 992px) {
        padding-right: 60px;
        padding-left: 60px;
        min-height: 120px;
        font-size: 4rem;
    }
}

// Tablet screens in portrait around size of iPad/iPad Pro.
@media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 1024px) {
    .bg-block-images {
        .row {
            .col-12:first-child {
                padding-left: 0 !important;
                padding-right: 5px;
            }

            .col-12:nth-child(2) {
                padding-left: 5px !important;
                padding-right: 5px;
            }

            .col-12:last-child {
                padding-left: 5px !important;
                padding-right: 0;
            }
        }
    }
}

// Tablet screens in landscape around size of iPad/iPad Pro.
@media screen and (orientation: landscape) and (min-width: 1024px) and (max-width: 1366px) {
    .bg-block-images {
        .row {
            .col-12:first-child {
                padding-left: 0 !important;
                padding-right: 5px;
            }

            .col-12:nth-child(2) {
                padding-left: 5px !important;
                padding-right: 5px;
            }

            .col-12:last-child {
                padding-left: 5px !important;
                padding-right: 0;
            }
        }
    }

}

@media screen and (max-width: 768px) {
    // bg-text-block

    .bg-block {
        padding: 1.5rem;
        padding-top: 4rem;
        border-left: 3rem solid white;
        border-right: 3rem solid white;

        .col-12 {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .footer-copyright,
    .footer-logo {
        text-align: left;
    }
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media screen and (orientation: portrait) and (min-width: 100px) and (max-width: 576px) {

    .big-text-block {
        padding-top: 7rem;
        padding-bottom: 7rem;

        h1 {
            font-size: 2.5rem;
        }
    }

    .bg-block {
        border-left: 0.5rem solid white;
        border-right: 0.5rem solid white;
        padding: 4rem 1rem 2rem;

        h1 {
            font-size: 2rem;
        }
    }

    .bg-feature-project {
        background-color: $block-background-color;
        padding: 2rem;
        margin-top: 2rem;
    }

    .blockquote {
        font-size: 1.5rem;
    }

    .block-image_row_split {
        .container-fluid {
            .row {
                .col-12:first-child {
                    padding-left: 0 !important;
                    padding-right: 0;
                }

                .col-12:last-child {
                    padding-left: 0 !important;
                    padding-right: 0;
                }
            }
        }
    }
}

// Column breaks for responsive Blog Index Page Cards
.person-icon {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

#people-list {
    @include media-breakpoint-only(xs) {
        column-count: 1;
    }

    @include media-breakpoint-between(sm, md) {
        column-count: 2;
    }

    @include media-breakpoint-only(lg) {
        column-count: 3;
    }

    @include media-breakpoint-only(xl) {
        column-count: 4;
    }
}

// common page banner
.page-banner {
    clip-path: ellipse(160% 100% at 50% 0);
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;

    .container-xxl {
        position: absolute;
    }

    img {
        object-position: center;
        object-fit: cover;
        height: 250px;
        width: 100vw;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 400px;
        }

        @media screen and (min-width: 992px) {
            height: 550px;
        }
    }

    h1 {
        text-shadow: 0 15px 40px rgba(0 0 0 / 35%);
        position: absolute;
        margin-bottom: 0;
        color: #FFFFFF;
    }

    .dark_gradient {
        opacity: 0.15;
    }
}

.page-details-wrapper {
    align-items: center;
    margin-top: 2rem;
    display: flex;

    @media screen and (max-width: 991px) {
        flex-direction: column;
        row-gap: 1rem;
    }

    @media screen and (min-width: 992px) {
        justify-content: center;
        flex-direction: row;
        column-gap: 2rem;
    }
}

.share-nav-container {
    $share-icon: 32px;

    .navigation-wrapper {
        justify-content: space-between;
        border-bottom: $border;
        border-top: $border;
        display: flex;
        width: 100%;

        & > div:first-child {
            justify-content: space-between;
            align-items: center;
            display: flex;
        }

        a.navigation {
            font-family: "Montserrat-SemiBold";
            color: $catalpa-primary-color;
            text-transform: uppercase;
            letter-spacing: 2px;
            align-items: center;
            column-gap: 10px;
            font-size: .875rem;
            display: flex;

            &:hover {
                color: $catalpa-primary-hover-color;
            }

            &[disabled] {
                pointer-events: none;
                opacity: .5;
            }
        }

        .share-wrapper {
            justify-content: space-between;
            align-items: center;
            column-gap: 1rem;
            display: flex;

            span {
                font-family: "Montserrat-SemiBold";
            }

            a {
                i:not([class]) {
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: $share-icon;
                    width: $share-icon;
                    display: block;
                }

                &#facebook-url i {
                    background-image: url("/src/images/Facebook.svg");

                    &:hover {
                        background-image: url("/src/images/Facebook-active.svg");
                    }
                }

                &#x-url i {
                    background-image: url("/src/images/x.svg");

                    &:hover {
                        background-image: url("/src/images/x-active.svg");
                    }
                }

                &#linkedin-url i {
                    background-image: url("/src/images/Linkedin.svg");

                    &:hover {
                        background-image: url("/src/images/Linkedin-active.svg");
                    }
                }
            }

            > div {
                align-items: center;
                column-gap: 1rem;
                display: flex;

                > div {
                    border: 1px solid $catalpa-primary-color;
                    border-radius: 17px;
                    position: relative;

                    &:hover {
                        background-color: $catalpa-primary-color;
                        cursor: pointer;

                        i {
                            color: #FFFFFF;
                        }
                    }

                    > a {
                        color: $catalpa-primary-color;
                        justify-content: center;
                        align-items: center;
                        height: $share-icon;
                        width: $share-icon;
                        display: flex;

                        i {
                            -webkit-text-stroke-width: 0.4px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .navigation-wrapper {
            flex-direction: column;
            padding-bottom: 2rem;
            padding-top: 2rem;
            row-gap: 1rem;

            a.navigation--desktop {
                display: none;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .navigation-wrapper {
            flex-direction: row;
            padding-bottom: 4rem;
            padding-top: 4rem;

            & > div:first-child {
                display: none;
            }
        }
    }
}

.dark_gradient {
    background: linear-gradient(90deg, #272314 0%, rgba(255 255 255 / 0%) 100%);
    position: absolute;
    opacity: 0.4;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
