// fade in top
@keyframes fade-in-top {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-in-top {
    animation: fade-in-top 0.2s ease-in both;
}

// Background slide in from top

@keyframes slide-in-top {
    0% {
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in-top {
    animation: slide-in-top 0.2s ease-in both;
}
