footer {
    @include make-container();
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;

    a {
        color: $secondary-font-color;
    }

    > div {
        margin-bottom: 2rem;
        text-align: center;

        > span {
            font-family: "Montserrat-SemiBold";
            color: $secondary-font-color;
            margin-bottom: 1.25rem;
            display: block;
        }

        i {
            padding: 0 4px;
            color: $red;
        }
    }

    ul {
        margin-bottom: 2rem;
        column-count: 2;
        padding-left: 0;

        li {
            font-family: "Montserrat-Medium";
            margin-bottom: ($gutter * 1.25);
            text-align: center;
            font-size: 0.88rem;
            list-style: none;

            > a {
                transition: all 0.2s ease;

                &:hover {
                    color: $catalpa-primary-color;
                    text-decoration: none;
                }
            }
        }
    }

    menu.socials {
        margin-bottom: 2rem;
    }

    p {
        font-family: "Montserrat-Regular";
        text-align: center;
        font-size: 0.75rem;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    footer ul {
        column-count: 3;
    }
}

@media screen and (min-width: 768px) {
    footer {
        @include make-row();
        gap: 2rem 1rem;

        > div {
            margin-bottom: 0;
            text-align: left;
            order: 1;
        }

        ul {
            margin-bottom: 0;
            column-count: 2;
            order: 2;
            flex: 1;

            li {
                text-align: left;
                font-size: 1rem;
            }
        }

        menu.socials {
            margin-bottom: 0;
            order: 3;
        }

        p {
            text-align: left;
            flex: 1 1 100%;
            order: 4;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    footer {
        padding-bottom: 6rem;
        padding-top: 6rem;
        gap: 2rem;

        ul {
            column-count: 3;
        }
    }
}

@media screen and (min-width: 1200px) {
    footer {
        padding-bottom: 8rem;
        padding-top: 8rem;
        gap: 4rem;

        ul {
            column-count: 4;
        }
    }
}
