.block-quote .quote-container.beige,
.block-media_quote {
    background-color: $block-background-color;
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;

    @media screen and (min-width: 991) and (max-width: 1199px) {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    @media screen and (min-width: 1200px) {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }
}

.block-quote,
.block-media_quote {
    blockquote {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        row-gap: 30px;
        display: flex;

        .quote {
            height: 20px;
        }

        h3 {
            text-align: center;
            margin-bottom: 0;
        }

        .author {
            align-items: center;
            column-gap: 10px;
            display: flex;

            i.la-minus {
                color: $secondary-font-color;
            }

            p {
                font-family: "Montserrat-Regular";
                color: $secondary-font-color;
                line-height: 1rem;
                font-size: .75rem;
                margin-bottom: 0;

                @media screen and (min-width: 992px) {
                    line-height: 2rem;
                    font-size: 1rem;
                }
            }
        }
    }
}

.block-media_quote {
    blockquote {
        @media screen and (min-width: 768px) {
            flex-direction: row;
            column-gap: 100px;
        }

        & > div:first-child {
            flex-direction: column;
            align-items: center;
            display: flex;
            row-gap: 30px;

            @media screen and (min-width: 768px) {
                justify-content: center;

                .quote {
                    display: none;
                }
            }

            picture img {
                border-radius: 50%;
                height: 110px;
                width: 110px;

                @media screen and (min-width: 992px) {
                    height: 170px;
                    width: 170px;
                }
            }
        }

        & > div:last-child {
            flex-direction: column;
            align-items: center;
            display: flex;
            row-gap: 30px;

            @media screen and (min-width: 768px) {
                align-items: flex-start;
            }

            .quote:first-child {
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            h3 {
                @media screen and (min-width: 768px) {
                    text-align: left;
                }
            }

            a {
                padding-top: 0;
            }
        }
    }
}
