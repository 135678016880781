.block-image_and_text_block {
    h3 {
        text-align: center;
    }

    .container-xxl {
        @media screen and (max-width: 575px) {
            padding: 0;
        }

        @media screen and (min-width: 768px) {
            &.image-left {
                :nth-child(odd) {
                    .image-wrapper {
                        left: 0;
                    }

                    .text-wrapper {
                        padding-left: 400px;
                        margin-left: 50px;
                    }
                }

                :nth-child(even) {
                    .image-wrapper {
                        right: 0;
                    }

                    .text-wrapper {
                        padding-right: 400px;
                        margin-right: 50px;
                    }
                }
            }

            &.image-right {
                :nth-child(even) {
                    .image-wrapper {
                        left: 0;
                    }

                    .text-wrapper {
                        padding-left: 400px;
                        margin-left: 50px;
                    }
                }

                :nth-child(odd) {
                    .image-wrapper {
                        right: 0;
                    }

                    .text-wrapper {
                        padding-right: 400px;
                        margin-right: 50px;
                    }
                }
            }
        }

        @media screen and (min-width: 992px) {
            &.image-left {
                :nth-child(odd) {
                    .text-wrapper {
                        padding-left: 500px;
                    }
                }

                :nth-child(even) {
                    .text-wrapper {
                        padding-right: 500px;
                    }
                }
            }

            &.image-right {
                :nth-child(even) {
                    .text-wrapper {
                        padding-left: 500px;
                    }
                }

                :nth-child(odd) {
                    .text-wrapper {
                        padding-right: 500px;
                    }
                }
            }
        }

        @media screen and (min-width: 1200px) {
            &.image-left {
                :nth-child(odd) {
                    .text-wrapper {
                        padding-left: 600px;
                    }
                }

                :nth-child(even) {
                    .text-wrapper {
                        padding-right: 600px;
                    }
                }
            }

            &.image-right {
                :nth-child(even) {
                    .text-wrapper {
                        padding-left: 600px;
                    }
                }

                :nth-child(odd) {
                    .text-wrapper {
                        padding-right: 600px;
                    }
                }
            }
        }
    }

    .item-wrapper {
        margin-bottom: 3.5rem;
        margin-top: 3.5rem;
        position: relative;
        flex-flow: column;
        display: flex;
        width: 100%;

        @media screen and (min-width: 992px) and (max-width: 1199px) {
            margin-bottom: 6rem;
            margin-top: 6rem;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 8rem;
            margin-top: 8rem;
        }

        .image-wrapper {
            @media screen and (min-width: 768px) {
                position: absolute;
            }

            img {
                object-position: center;
                object-fit: cover;
                height: 320px;
                width: 100%;

                @media screen and (min-width: 768px) and (max-width: 991px) {
                    height: 400px;
                    width: 400px;
                }

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    height: 500px;
                    width: 500px;
                }

                @media screen and (min-width: 1200px) {
                    height: 600px;
                    width: 600px;
                }

                @media screen and (max-width: 767px) {
                    border-radius: 6px 6px 0 0;
                }

                @media screen and (min-width: 768px) {
                    border-radius: 6px;
                }
            }
        }

        .text-wrapper {
            padding: 1.5rem calc(var(--bs-gutter-x)* 0.5) .5rem calc(var(--bs-gutter-x)* 0.5);
            background-color: $block-background-color;

            @media screen and (max-width: 767px) {
                padding: 2.5rem 2.5rem 1.5rem 2.5rem;
                border-radius: 0 0 6px 6px;
            }

            @media screen and (min-width: 768px) {
                padding: 80px 50px calc(80px - 1rem) 50px;
                border-radius: 6px;
                min-height: 400px;
                margin-top: 80px;
            }

            @media screen and (min-width: 1200px) {
                min-height: 600px;
            }

            div {
                background-color: #FDFDF1;
                justify-content: center;
                margin-bottom: 1.5rem;
                border-radius: 45px;
                align-items: center;
                display: flex;
                height: 90px;
                width: 90px;

                @media screen and (min-width: 992px) {
                    border-radius: 64px;
                    height: 128px;
                    width: 128px;
                }
            }

            h4,
            p {
                margin-bottom: 1rem;

                @media screen and (min-width: 992px) {
                    margin-bottom: 1.5rem;
                }
            }

            p:empty {
                display: none;
            }
        }
    }
}
