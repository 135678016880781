.block-elements_block {
    .block-wrapper {
        padding-bottom: 3.5rem;
        padding-top: 3.5rem;

        @media screen and (min-width: 992px) and (max-width: 1199px) {
            padding-bottom: 6rem;
            padding-top: 6rem;
        }

        @media screen and (min-width: 1200px) {
            padding-bottom: 8rem;
            padding-top: 8rem;
        }

        &.beige {
            background-color: $block-background-color;
        }

        &.white {
            padding-top: 0;

            &:not(.border_bottom) {
                padding-bottom: 0;
            }
        }
    }

    h3 {
        margin-bottom: 3rem;
    }

    .border_bottom {
        border-bottom: $border;
    }

    .item-wrapper {
        display: flex;
        flex-flow: row nowrap;
        gap: 2rem;
        margin-bottom: 2rem;

        img {
            object-fit: contain;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
        }

        ul,
        ol {
            list-style-position: outside;
            padding-left: 15px;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }

        li,
        p {
            font-family: "Montserrat-Regular";
            color: $secondary-font-color;
            line-height: 1.625rem;
            font-size: 1rem;
        }


    }

    .quote-wrapper {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;

        > span {
            background: url("../images/quote-arrow.png");
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            height: 50px;
            margin-top: 1.75rem;
            min-width: 50px;
            width: 50px;

            &.down {
                transform: scaleY(-1);
                margin-top: 3rem;
            }
        }

        p {
            color: $catalpa-primary-color;
            font-family: 'Caveat-SemiBold';
            font-size: 1.65rem;
            margin: 1rem;
            transform: rotate(-5deg);
        }

        &:has(.down) {
            margin-left: auto;
        }
    }

    @media screen and (min-width: 992px) {

        .content-wrapper {
            display: flex;
            flex-flow: row wrap;
            gap: 3rem;

            .item-wrapper {
                margin: 0;
            }

            &.two {
                justify-content: flex-start;

                .item-wrapper {
                    width: calc((100% - 3rem) / 2);
                }

                .quote-wrapper {
                    max-width: calc((100% - 3rem) / 2);
                }
            }

            &.three {
                justify-content: center;

                .item-wrapper {
                    flex-flow: column;
                    gap: 1rem;
                    margin: 0;
                    width: calc((100% - 6rem) / 3);
                }

                .quote-wrapper {
                    max-width: calc((100% - 6rem) / 3);
                }
            }
        }


        .quote-wrapper {
            // calc here is width of icon of other items
            // plus gap setting of container
            margin-left: calc(27px + 2rem);
            max-width: 45%;

            &:is(:nth-child(even)) {
                margin-left: 0;
                max-width: 49%;
            }

            span {
                max-width: 45px;
            }

            p {
                margin-left: 0;
            }
        }
    }
}
