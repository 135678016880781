.block-image_carousel {
    img {
        object-fit: cover;
        width: 100%;

        @media screen and (max-width: 991px) {
            height: 320px;
        }

        @media screen and (min-width: 992px) {
            height: 425px;
        }
    }
}
