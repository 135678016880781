.block-text_and_list_block {

    h3 {
        text-align: center;
        margin: 1rem 0 2rem;
    }

    .content-wrapper {
        background-color: $block-background-color;
        margin-bottom: 3.5rem;
        margin-top: 3.5rem;
        padding: 3rem calc(var(--bs-gutter-x) * 0.5);

        @media screen and (min-width: 992px) {
            margin-bottom: 6rem;
            margin-top: 6rem;
            padding: 3rem;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 8rem;
            margin-top: 8rem;
        }

        .icon-wrapper {
            background-color: #FDFDF1;
            justify-content: center;
            margin-bottom: 1.5rem;
            align-items: center;
            border-radius: 50%;
            display: flex;
            height: 125px;
            width: 125px;
        }

        .blocks {
            display: flex;
            flex-direction: column;

            h4 {
                margin-bottom: 1rem;
            }

            p {
                font-family: "Montserrat-Regular";
                font-size: 1rem;
            }
        }
    }

    .list-wrapper {

        ul,
        ol,
        li,
        div,
        p {
            color: $secondary-font-color;
            font-family: "Montserrat-Regular";
            font-size: 1rem;

            b {
                font-family: 'Montserrat-SemiBold';
            }

            li {
                margin-bottom: 0.85rem;
            }
        }

        ul,
        ol {
            padding-left: 1rem;

            &:last-child,
            li:last-child {
                margin-bottom: 0;
            }
        }

        ul ::marker {
            font-size: 0.6rem;
        }

        ol ::marker {
            font-family: 'Montserrat-SemiBold';
        }

    }

    @media screen and (max-width: 991px) {
        .container-xxl {
            margin: 0;
            max-width: 100%;
            padding: 0;
        }

        .content-wrapper .icon-wrapper {
            height: 90px;
            width: 90px;
        }
    }

    @media screen and (min-width: 992px) {
        .content-wrapper {

            .blocks {
                flex-flow: row nowrap;
                justify-content: space-between;

                p {
                    font-size: 1.15rem;
                    line-height: 1.75rem;
                }

                .left-wrapper {
                    flex: 0 0 38%;
                }

                .list-wrapper {
                    flex: 0 0 58%;

                    ul,
                    ol,
                    li,
                    div,
                    p {
                        font-size: 1.15rem;
                    }
                }
            }
        }
    }
}
