.block-header_text {
    padding-top: 100px;

    @media screen and (min-width: 768px) {
        padding-top: 150px;
    }

    h1 {
        margin-bottom: 2rem;

        @media screen and (min-width: 992px) {
            margin-bottom: 3rem;
        }
    }

    p {
        font-family: "Montserrat-Regular";
        line-height: 1.5rem;
        font-size: 1rem;

        @media screen and (min-width: 992px) {
            line-height: 2.75rem;
            font-size: 1.75rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
