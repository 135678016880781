/* Mobile first */
/* catalpians section on pages */

.generalpage.modal-open {

    > main,
    #mainNavigation {
        filter: blur(5px);
    }
}


// container and img styles
.circular-clip {
    clip-path: circle(70px at center);
    display: inline-block;
    background: $block-background-color;
}

/* the full width container that the Our People section sites in */
.container-catalpians {
    position: relative;
    background-color: $block-background-color;
    margin-top: 100px;
    padding-bottom: 4rem;
    padding: 2rem 0 3rem 0;
    clip-path: ellipse(165% 100% at 50% 100%);

    @media screen and (max-width: 991px) {
        clip-path: ellipse(255% 100% at 50% 100%);
    }

    @media screen and (min-width: 992px) {
        padding-bottom: 6rem;
    }

    h3 {
        padding-top: 2rem;
        margin-bottom: 2.5rem;

        @media screen and (min-width: 768px) {
            padding-top: 3.5rem;
            margin-bottom: 4rem;
        }
    }

    .catalpian-outer-wrapper {
        justify-content: center;
        display: grid;
        row-gap: 2rem;
    }

    .block-action {
        margin-top: 2rem;

        a {
            display: block;
            margin: 0 auto;
        }
    }
}

/* the flex layout for the grid of catalpian elements */
.container-catalpians {

    /* Each block inside the catalpians section */
    .block-catalpian {
        padding: 0 1rem;

        /* images are greyscaled */
        img {
            filter: grayscale(100%);
        }

        /* The overlay hidden initially*/
        .hover-link-holder {
            position: relative;

            .hover-link {
                position: absolute;
                width: 140px;
                height: 140px;
                top: 0;
                justify-content: center;
                align-items: center;
                display: none;
                cursor: pointer;

                i {
                    display: none;
                }
            }
        }

        /* The overlay shown on hover with blurred image */
        .hover-link-holder:hover {

            .hover-link {
                display: flex;
                gap: 1rem;
            }
        }

        .mobile-hover-links {
            a:not(:only-of-type):not(:last-of-type) {
                margin-right: 0.6rem;
            }

            i {
                color: $light-font-color;
                font-size: 1.6rem;

                &:hover {
                    cursor: pointer;
                    color: $catalpa-primary-color;
                }
            }
        }

    }

    @media screen and (min-width: 576px) {
        .block-catalpian {
            .hover-link-holder {
                .hover-link {
                    background-color: rgba(30, 156, 176, 0.8);

                    /* icons are white with black hover */
                    i {
                        display: inline-block;
                        color: white;
                        font-size: 1.5em;
                    }

                    i:hover {
                        color: $darkest-font-color;
                    }

                }
            }

            /* The overlay shown on hover with blurred image */
            .hover-link-holder:hover {
                img {
                    filter: blur(5px);
                }
            }

            .mobile-hover-links {
                display: none;
            }
        }
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
        .catalpian-outer-wrapper {
            grid-template-columns: repeat(auto-fit, calc(504px / 2));
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .catalpian-outer-wrapper {
            grid-template-columns: repeat(auto-fit, calc(680px / 3));
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        .catalpian-outer-wrapper {
            grid-template-columns: repeat(auto-fit, calc(885px / 4));
        }
    }

    @media screen and (min-width: 1200px) {
        .catalpian-outer-wrapper {
            grid-template-columns: repeat(auto-fit, calc(1076px / 5));
        }
    }
}

// Small phones around the size of an iPhone 5S.
@media screen and (min-width: 576px) {
    .modal-body {
        padding: 0 4rem 4rem 4rem;
    }
}

/* Modal Styles*/
#catalpianModal {
    --bs-modal-width: 650px;

    background-color: rgba(245, 245, 237, 0.9) !important;

    .modal-header {
        border-bottom: 0;

        &.desktop {
            display: none;
        }
    }

    .btn.btn-ghost {
        background-color: $block-background-color;
        border: 2px solid $catalpa-primary-color;
        color: $catalpa-primary-color;
        justify-content: center;
        border-radius: 25px;
        align-items: center;
        font-size: 1.25rem;
        display: flex;
        height: 45px;
        width: 45px;
        padding: 0;
    }

    .modal-content {
        border: 1px solid #D1D1C5;
        border-radius: 20px;
        background-color: #FEFEF7;
    }

    .modal-body {
        padding: 1rem 2rem;

        .avatar_name {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            gap: 2rem;
            max-width: 100%;
            margin-bottom: 1rem;

            div:last-child {
                flex-direction: column;
                display: flex;
                row-gap: 5px;
            }
        }

        h6 {
            margin-bottom: 0;
        }
    }

    .bio {
        overflow: scroll;
        padding-bottom: 10%;
        padding-right: 1rem;
        max-height: 35vh;
        // -webkit-mask: linear-gradient(180deg,#0000,#000 5% 80%,#0000);

        > p:last-of-type {
            margin-bottom: 0;
        }

        // scrollbar

        &::-webkit-scrollbar {
            height: 4px;
            min-height: 4px;
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D1D1C5;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track:vertical {
            background-color: $block-background-color;
            border-radius: 8px;
            margin-top: 2rem;
            margin-bottom: 2.5rem;
        }

        // For Internet Explorer
        & {
            scrollbar-face-color: #D1D1C5;
            scrollbar-track-color: $block-background-color;
        }
    }

    .link-wrapper {
        padding-top: 1.5rem;
        position: relative;

        a {
            border: 2px solid $catalpa-primary-color;

            &.linkedin {
                border-radius: 50%;
                padding: 10px 6px;

                i {
                    font-size: 1.6rem;
                    vertical-align: middle;
                }
            }

            &.contact {
                margin-right: 1rem;
                padding: 10px 16px;
                border-radius: 21px;

                i {
                    font-size: 1.25rem;
                    margin-right: 0.6rem;
                    vertical-align: text-top;
                }

                span {
                    font-family: 'Montserrat-SemiBold';
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    letter-spacing: 1.75px;
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: -49px;
            height: 49px;
            width: 100%;
            background-image: linear-gradient(rgba(254, 254, 247, 0.2), rgba(254, 254, 247, 1));
            // background-image: linear-gradient(rgba(250, 128, 114, 0.2), rgba(250, 128, 114, 1));
        }
    }

    @media screen and (min-width: 768px) {
        .modal-header {

            &.desktop {
                display: inline-block;
                position: absolute;
                top: 0;
                right: 5%;
            }

            &.mobile {
                display: none;
            }
        }

        .modal-body {
            padding: 3rem 4rem;
        }
    }

}
