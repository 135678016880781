.cpy-tooltip {
    background: $secondary-font-color;
    border-radius: 4px;
    text-align: center;
    font-size: 0.75rem;
    position: absolute;
    color: #FFFFFF;
    display: none;
    padding: 5px;
    width: 130px;
    right: 0;

    &:after {
        content: '';
        border-top: 0.75rem solid $secondary-font-color;
        border-bottom: 0.75rem solid transparent;
        border-right: 0.75rem solid transparent;
        transform: rotate(45deg);
        position: absolute;
        right: 13px;
        height: 0;
        top: -6px;
        width: 0;
    }
}

a:hover ~ .cpy-tooltip {
    display: block;
    top: 40px;

    &:after {
        display: block;
    }
}
