.block-header_image {
    clip-path: ellipse(160% 100% at 50% 0);
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;

    img {
        object-position: center;
        object-fit: cover;
        height: 425px;
        width: 100vw;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 600px;
        }

        @media screen and (min-width: 992px) {
            height: 1050px;
        }
    }

    .container-xxl {
        position: absolute;
        right: 0;
        left: 0;

        div {
            flex-direction: column;
            display: flex;
            row-gap: 1rem;

            @media screen and (min-width: 992px) {
                row-gap: 2rem;

                .btn {
                    padding: 1rem 2rem !important;
                    font-size: 1rem !important;
                }
            }

            h1,
            h5 {
                text-shadow: 0 15px 40px rgba(0 0 0 / 35%);
                margin-bottom: 0;
                color: #FFFFFF;
            }
        }
    }
}
