.block-body_image {
    [class^="grid-"] {
        display: grid;
        gap: 5px;

        @media screen and (min-width: 576px) {
            gap: 30px;
        }

        img {
            border-radius: 6px;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .grid-1 {
        grid-template-columns: 1fr;
    }

    .grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-3 {
        grid-template-columns: .6fr .4fr;

        :first-child {
            grid-row: 1 / span 2;
        }

        :nth-child(2),
        :nth-child(3) {
            grid-column: 2;
        }
    }

    .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}
